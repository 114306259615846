/* DIN Round W01 Bold */
@font-face {
  font-family: 'DIN Round W01 Bold';
  font-display: swap;
  src: url('/fonts/DINRoundW01Regular.woff2') format('woff2'), url('/fonts/DINRoundW01Regular.woff') format('woff');
}
/* DIN Round W01 Regular */
@font-face {
  font-family: 'DIN Round W01 Regular';
  font-display: swap;
  src: url('/fonts/DINRoundW01Regular.woff2') format('woff2'), url('/fonts/DINRoundW01Regular.woff') format('woff');
}
/*
@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Thin_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-ThinItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-ThinItalic_Web.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-XLight_Web.woff2') format('woff2'),
		url('/fonts/Gotham-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-XLightItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-XLightItalic_Web.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Light_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-LightItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-LightItalic_Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}
*/
@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Book_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
/*
@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-BookItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-BookItalic_Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Medium_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-MediumItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-MediumItalic_Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Bold_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-BoldItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-BoldItalic_Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}
*/
@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Black_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
/*
@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-BlackItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-BlackItalic_Web.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Ultra_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Ultra_Web.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-UltraItalic_Web.woff2') format('woff2'),
		url('/fonts/Gotham-UltraItalic_Web.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}
*/


html,
body {
  padding: 0;
  margin: 0;
  font-family: "DIN Round W01 Bold", Arial, Helvetica, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

strong {
  font-family: "DIN Round W01 Bold";
}
